export const useEventsStore = defineStore('events', {
  state: () => {
    return {
      isEventsLoading: false,
    };
  },

  actions: {
    async getEvents(params) {
      this.isEventsLoading = true;

      const response = await useApi.get('/events', {
        params,
      }).catch(() => {
        useToast.error(useT('abc314'));
      });

      if (response) {
        this.isEventsLoading = false;
      }

      return response;
    },
  },

  getters: {

  },
});
